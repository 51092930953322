<template>
  <b-form @submit="onSubmit" v-if="show">
    <b-row class="spacing">
      <b-col sm="4" class="labelContainer">
        <label class="spacing">Kullanıcı Tipi</label>
      </b-col>
      <b-col sm="8" class="spacing">
        <b-form-radio-group
          id="radio-group-1"
          v-model="form.selected"
          :options="options"
          name="radio-options"
        ></b-form-radio-group>
      </b-col>
    </b-row>

    <b-row class="spacing" v-if="form.selected == 'p'">
      <b-col sm="4" class="labelContainer">
        <label class="spacing">TC Kimlik No</label>
      </b-col>
      <b-col sm="8">
        <b-form-input id="input-1" v-model="form.ssn" type="number" required />
      </b-col>
    </b-row>
    <b-row class="spacing" v-else>
      <b-col sm="4" class="labelContainer">
        <label class="spacing">Kulüp</label>
      </b-col>
      <b-col sm="8">
        <b-form-select
          v-model="form.clubId"
          :options="clubs"
          text-field="name"
          value-field="id"
          required
        >
        </b-form-select>
      </b-col>
    </b-row>

    <b-row class="spacing">
      <b-col sm="4" class="labelContainer">
        <label class="spacing">Şifre</label>
      </b-col>
      <b-col sm="8">
        <b-form-input
          id="input-2"
          v-model="form.password"
          type="password"
          required
        />
      </b-col>
    </b-row>

    <b-row class="spacing">
      <VueRecaptcha
        ref="recaptcha"
        @verify="onCaptchaVerified"
        @expired="onCaptchaExpired"
        size="invisible"
        sitekey="6Ldz3QgfAAAAAAy-TksDqe7AaViHD9AUrh7R0-A5"
      />
    </b-row>
    <b-form-row class="center spacing">
      <b-col>
        <b-button type="reset" variant="danger">İptal</b-button>
      </b-col>
      <b-col>
        <b-button type="submit" variant="success">Gönder</b-button>
      </b-col>
    </b-form-row>
  </b-form>
</template>
<script>
import { mapGetters } from "vuex";
import { VueRecaptcha } from "vue-recaptcha";
export default {
  name: "LoginPage",
  components: { VueRecaptcha },
  computed: {
    ...mapGetters(["getClubNames", "getPopupImage"]),
    clubs: {
      get() {
        return [{ name: "Seçin", id: 0 }].concat(this.getClubNames);
      },
    },
  },
  created() {
    this.$store.dispatch("getClubNames");
    this.$store.dispatch("initPopupImage");
  },
  beforeUpdate() {
    if (!this.$cookie.get("popup_window")) {
      this.$cookie.set("popup_window", "true", 15);
      this.popupMessage();
    }
  },
  data() {
    return {
      form: {
        ssn: "",
        password: "",
        captcha: null,
        selected: "p",
        clubId: 0,
      },
      options: [
        { text: "Personel", value: "p" },
        { text: "Kulüp", value: "k" },
      ],

      show: true,
    };
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      this.$refs.recaptcha.execute();
    },
    popupMessage() {
      const h = this.$createElement;
      const messageVNode = h("div", [
        h("b-img", {
          props: {
            src: this.getPopupImage,
            center: true,
            fluid: true,
          },
        }),
      ]);
      // We must pass the generated VNodes as arrays
      this.$bvModal.msgBoxOk([messageVNode], {
        buttonSize: "sm",
        headerClass: "p-2 border-bottom-0",
        footerClass: "p-2 border-top-0",
        centered: true,
        size: "sm",
      });
    },
    onCaptchaVerified: function (recaptchaToken) {
      this.form.recaptchaToken = recaptchaToken;
      this.$refs.recaptcha.reset();
      this.$store.dispatch("login", this.form).then(() => {
        this.$router.push("/");
      });
    },
    onCaptchaExpired: function () {
      this.$refs.recaptcha.reset();
    },
  },
};
</script>


<style scoped>
html,
body {
  background-color: black;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.labelContainer {
  display: grid;
  place-items: center;
}
.buttonBar {
  text-align: end;
}
.header {
  background: rgb(5, 114, 184);
  background: linear-gradient(
    0deg,
    rgba(5, 114, 184, 1) 40%,
    rgba(17, 150, 236, 1) 85%
  );
}
.center {
  text-align: center;
}
.spacing {
  margin-top: 0.5rem;
}
</style>